class Popup {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.button = this.root.querySelector('.js-close-popup');
        this.submit = this.root.querySelector('.js-submit-popup');
        this.closed = localStorage.getItem('close_popup');
        this.expire = localStorage.getItem('close_popup_expire');
        return true;
    }

    init() {
        this.setButton();
        let date_now = Date.now();
        if (
            !this.closed ||
            this.closed != this.submit.href ||
            !this.expire ||
            this.expire < date_now
        ) {
            this.root.classList.remove('is-inactive');
        }
    }

    setButton() {
        if (!this.button) return;
        this.onButtonSubmit = this.onSubmit.bind(this);
        this.button.addEventListener('click', this.onButtonSubmit);
        this.submit.addEventListener('click', this.onButtonSubmit);
        // hotfix
        this.button.addEventListener('touchstart', this.onButtonSubmit);
        this.submit.addEventListener('touchstart', this.onButtonSubmit);
    }

    onSubmit() {
        localStorage.setItem('close_popup', this.submit.href);

        let date = new Date();
        let new_date = date.setDate(date.getDate() + 60);
        localStorage.setItem('close_popup_expire', new_date);

        this.root.classList.add('is-inactive');
    }
}

// export default function cookies() {
const popup = document.querySelector('.js-popup');
setTimeout(() => {
    if (popup) new Popup(popup);
}, 1000);
// }
